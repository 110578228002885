
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getPanel } from "@/api/encounter.api";
import { getDropdownsByType } from "@/api/dropdown.api";
import { searchServices } from "@/api/services.api";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { PaymentService } from "@/modules/encounter/encounter.model";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import {
  PatientInsurance,
  selfPayer,
} from "../patientPayer/patientPayer.model";
import { searchDictInsuranceCompanys } from "@/api/code-master-insurance-company.api";
import DateComponent from "@/components/ABilling/DateComponent.vue";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface StatusCode {
  code: string;
  description: string;
}

export interface Data {
  items: PaymentService[];
  payment: any;
  patientId: any;
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    patientPayers: PatientInsurance[];
    facilities: { id: string }[];
    actualPatientPayers: PatientInsurance[];
    actualFacilities: { id: string }[];
    paymentTypes: [];
    adjustmentReasonCodes: any[],
    transactionTypes: [];
  };
  postingErrors: string[];
}
export default defineComponent({
  name: "ServiceHistoryPostedPayment",
  props: ["patientId", "payment"],
  components: { Multiselect, PaginationUi, DateComponent },
  setup(props) {
    const store = useStore();
    const orderList = [
      {
        name: "Encounter ID",
        key: "encounter.encounterId",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Service Date",
        key: "serviceDate",
        isFilter: true,
        keyword: null,
        formType: "isDate",
      },
      {
        name: "Facility",
        key: "encounter.facility.name",
        isFilter: true,
        keyword: null,
        formType: "facility",
      },
      {
        name: "Service",
        key: "service",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Description",
        isFilter: true,
        key: "description",
        keyword: null,
      },
      {
        name: "Total Charges",
        key: "totalCharges",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "number",
      },
      {
        name: "Balance Due",
        key: "balanceDue",
        isFilter: false,
        keyword: null,
        formType: "number",
      },
      {
        name: "Current Responsible Party",
        key: "encounter.responsibleParty",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
        formType: "patientPayer",
      },
    ];

    let organizationId = ref<string | null>("");

    watch(
      () => props.patientId,
      (currentValue, oldValue) => {
        if (currentValue) {
          getAll();
        }
      },
      { deep: true }
    );

    let data = reactive<Data>({
      payment: undefined,
      patientId: props.patientId,
      items: [],
      keyword: "",
      orderBy: "createdOn",
      IsAdvancedOrderBy: false,
      isDecr: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 10,
      },
      panel: {},
      dict: {
        patientPayers: [],
        facilities: [],
        actualPatientPayers: [],
        actualFacilities: [],
        paymentTypes: [],
        transactionTypes: [],
        adjustmentReasonCodes: [],
      },
      postingErrors: [],
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "createdOn";
      data.IsAdvancedOrderBy = false;
      orderList.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      //setCurrentPageTitle("Service Management");
      data.dict.paymentTypes = store.getters.allPaymentTypes;
      data.dict.transactionTypes = store.getters.allTransactionTypes;
      let adjustmentReasonCodes = await getDropdownsByType({
        type: "Adjustment Types",
      });
      data.dict.adjustmentReasonCodes = adjustmentReasonCodes.data;

      data.dict.patientPayers = await searchDictInsuranceCompanys({
        search: "",
      });
      data.dict.patientPayers.unshift(selfPayer);

      const panel = await getPanel();
      data.dict.facilities = panel.facilities;
      data.payment = props.payment;

      await getAll();
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let constList = orderList
        .filter((item) => {
          return !item.IsAdvancedOrderBy;
        })
        .map((item) => {
          return item.key;
        });
      constList.push("patient.firstName");
      let request = {
        encounterStatus: ["Billed", "BillPatient", "SubmittedToClearinghouse"],
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        PatientId: props.patientId,
        EncounterId: getFilter("encounter.encounterId"),
        ServiceDate: getFilter("serviceDate"),
        Facility: getFilter("encounter.facility.name"),
        Service: getFilter("service"),
        Description: getFilter("description"),
        DaysOrUnits: getFilter("daysOrUnits"),
        ChargePerUnit: getFilter("chargePerUnit"),
        TotalCharges: getFilter("totalCharges"),
        BalanceDue: getFilter("balanceDue"),
        CurrentResponsibleParty: getFilter("encounter.responsibleParty"),
        PaymentId: data.payment.id,
      };

      const res = await searchServices(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      updateFilters(res);
    }

    function updateFilters(res) {
      //This we need because vue multiselect throw EX if we have value but options list is empty
      if (res.responsibleParty.length == 0) {
        cleanHeaderFilter("encounter.responsibleParty");
      }
      if (res.facility.length == 0) {
        cleanHeaderFilter("encounter.facility.id");
      }

      //by the request result update filters to contains only actual data
      data.dict.actualPatientPayers = data.dict.patientPayers.filter((c) => {
        return res.responsibleParty.find((f) => {
          return f == c.payerId;
        });
      });

      data.dict.actualFacilities = data.dict.facilities.filter((c) => {
        return res.facility.find((f) => {
          return f == c.id;
        });
      });
    }

    function cleanHeaderFilter(headerKey) {
      (getFilterItem(headerKey) as any).keyword = null;
    }

    function getFilterItem(key) {
      const header = orderList.find((item) => {
        return item.key == key;
      });
      return header;
    }

    function getFilter(key) {
      const header = orderList.find((item) => {
        return item.key == key;
      });
      if (header && header.keyword) {
        return header.keyword;
      }
      return null;
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    return {
      organizationId,
      clearSearch,
      data,
      orderList,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
    };
  },
});
